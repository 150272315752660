<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-row class="mt-2 ml-2 pt-0">
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="selectedStartDate"
              outlined
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              v-model="selectedEndDate"
              outlined
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col>
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else
              icon
              class="primary "
              @click="getClients()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>

        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ usreList.length }}) List Of Clients</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="usreList"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
              <small>{{ item.no_campaigns }}</small>
            </div>
          </template>
          <template #[`item.amount`]="{item}">
            {{ item.amount }}
          </template>
          <template #[`item.amount_saved_so_far`]="{item}">
            {{ item.amount_saved_so_far }}
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Active: 'primary',
      Validated: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Principal Campaign', value: 'amount' },
        { text: 'Cash Flow', value: 'amount_saved_so_far' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      usreList: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      // this.getClients();
      document.title = "TopUp | List of Clients"
  },

  methods:{
    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    },

    async getClients(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      this.loading = true
      await axios
        .get('/api/v1/topup/get/my_clients/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.usreList = response.data  // get the data and fill into campaigns
          console.log(this.usreList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
  },
}
</script>
